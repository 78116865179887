import * as React from "react";
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

import {
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const who = [
  {
    name: "Our story",
    href: "/who-we-are/our-story",
    description:
      "Learn more about Avert and the vision, mission and values that underpinned our work",
    icon: ChartBarIcon,
  },
  {
    name: "Our people ",
    href: "/who-we-are/our-people",
    description: "Meet the team behind Avert, including our Board of Trustees",
    icon: CursorClickIcon,
  },
  // {
  //   name: "Our partners",
  //   href: "/who-we-are/our-partners",
  //   description: "Find out about our range of partners from around the world",
  //   icon: ShieldCheckIcon,
  // },
  {
    name: "Key reports",
    href: "/who-we-are/our-annual-reports",
    description: "Read and download our annual reports and other key reports",
    icon: ViewGridIcon,
  },
];

const what = [
  // {
  //   name: "Our legacy",
  //   href: "/what-we-do/our-strategy",
  //   description:
  //     "Read and download our latest strategy, 'From knowldege to action'",
  //   icon: RefreshIcon,
  // },
  // {
  //   name: "Our approach",
  //   href: "/what-we-do/our-approach",
  //   description: "Understand our commitment and approach to health literacy",
  //   icon: ChartBarIcon,
  // },
  {
    name: "What we did",
    href: "/what-we-do/our-work",
    description:
      "Explore Avert's work in detail - our key programmes and what they achieved",
    icon: ChartBarIcon,
  },
  {
    name: "Our transition",
    href: "/what-we-do/our-transition",
    description:
      "Read about Avert’s journey transitioning its work to partners in Africa",
    icon: RefreshIcon,
  },
  {
    name: "Our impact",
    href: "/what-we-do/our-impact",
    description: "A visual summary of the difference our work has made",
    icon: RefreshIcon,
  },
  {
    name: "Our legacy",
    href: "/what-we-do/our-legacy",
    description:
      "Read about Avert's contribution to the global HIV response over its 38-year lifetime",
    icon: RefreshIcon,
  },
];

const news = [
  // {
  //   name: "News and updates",
  //   href: "/news-and-events",
  //   description: "Read the latest news and updates from Avert",
  //   icon: RefreshIcon,
  // },
  // {
  //   name: "Avert newsletter",
  //   href: "/newsletter",
  //   description: "Sign up to our mailing list for regular updates on our work",
  //   icon: ChartBarIcon,
  // },
  {
    name: "HIV timeline",
    href: "/news-and-events/hiv-timeline",
    description: "Explore the history and events of the HIV epidemic",
    icon: ChartBarIcon,
  },
];

const news_articles = [
  {
    name: "HIV treatment news",
    href: "#",
    description: "Delivering HIV treatment via van: four reasons why it works",
    icon: CursorClickIcon,
    submenus: [
      {
        name: "Delivering HIV treatment via van",
        href: "#",
        description:
          "More people with HIV benefited from effective treatment after it was ...",
        imageg: "thumb-1.png",
      },
      {
        name: "New HIV vaccine trial starts in Africa",
        href: "#",
        description:
          "The first phase of a trial for a new HIV vaccine has begun in Zambia ...",
        imageg: "thumb-2.png",
      },
    ],
  },
];

const callsToAction = [{ name: "Watch Demo", href: "#", icon: PlayIcon }];

const navigation = [
  { name: "Who we are", href: "#" },
  { name: "Our work", href: "#" },
  { name: "HIV timeline ", href: "#" },
  // { name: "Donate", href: "https://cafdonate.cafonline.org/19766" },
];

const TopNav = () => {
  return (
    <>
      <Popover>
        <div className="relative pt-6 px-2">
          {/* <div className="relative pt-6 px-2 sm:px-6 lg:px-6"> */}
          <nav
            className="relative flex items-center justify-between sm:h-10 lg:justify-start"
            aria-label="Global"
          >
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/">
                  <span className="sr-only">Avert</span>
                  <StaticImage
                    className=""
                    src="../images/avert-logo-white.svg"
                    alt="Avert logo"
                    placeholder="TRACED_SVG"
                    layout="fixed"
                    width={150}
                    height={38}
                  />
                </Link>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-2 ml-6">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-white" : "text-white",
                        "group text-white rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white"
                      )}
                    >
                      <span>Who we are</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-white" : "text-white",
                          "ml-2 h-5 w-5 group-hover:text-white"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {who.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                              >
                                <ChevronRightIcon
                                  className="flex-shrink-0 h-6 w-6 text-gray-700"
                                  aria-hidden="true"
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                          {/* <div className="px-5 py-5 bg-white space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                         {callsToAction.map((item) => (
                           <div key={item.name} className="flow-root">
                             <a
                               href={item.href}
                               className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 bg-yellow"
                             >
                               <item.icon
                                 className="flex-shrink-0 h-6 w-6 text-gray-400"
                                 aria-hidden="true"
                               />
                               <span className="ml-3">{item.name}</span>
                             </a>
                           </div>
                         ))}
                       </div> */}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-white" : "text-white",
                        "group text-white rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white"
                      )}
                    >
                      <span>Our work</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-white" : "text-white",
                          "ml-2 h-5 w-5 group-hover:text-white"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {what.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="-m-3 p-2 flex items-start rounded-lg hover:bg-gray-50"
                              >
                                <ChevronRightIcon className="flex-shrink-0 h-6 w-6 text-gray-700" />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  {item.submenus &&
                                    item.submenus.map((menu) => (
                                      <div key={menu.name}>
                                        <div className="mx-auto flex pt-2">
                                          <div>
                                            <ChevronRightIcon
                                              className="text-marineDark ml-3 h-6 w-6 "
                                              aria-hidden="true"
                                            />
                                          </div>
                                          <Link to={menu.href && menu.href}>
                                            <div>
                                              <p className="text-base font-medium text-gray-900">
                                                {menu.name && menu.name}
                                              </p>
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                    ))}
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-white" : "text-white",
                        "group text-white rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white"
                      )}
                    >
                      <span>HIV timeline</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-white" : "text-white",
                          "ml-2 h-5 w-5 group-hover:text-white"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {news.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="-m-3 p-2 flex items-start rounded-lg hover:bg-gray-50"
                              >
                                <ChevronRightIcon
                                  className="flex-shrink-0 h-6 w-6 text-gray-700"
                                  aria-hidden="true"
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>

                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                          {/* <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                         {callsToAction.map((item) => (
                           <div key={item.name} className="flow-root">
                             <a
                               href={item.href}
                               className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                             >
                               <item.icon
                                 className="flex-shrink-0 h-6 w-6 text-gray-400"
                                 aria-hidden="true"
                               />
                               <span className="ml-3">{item.name}</span>
                             </a>
                           </div>
                         ))}
                       </div> */}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {/* <div className="">
           <a
             href="#"
             className=" whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-marineDark hover:bg-indigo-700"
           >
             Donate
           </a>
         </div> */}
            </Popover.Group>
            {/* <div className="ml-2">
              <a
                target="_blank"
                href="https://cafdonate.cafonline.org/19766"
                className={
                  "whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-bold text-black bg-white hover:bg-white no-underline"
                }
              >
                Donate
              </a>
            </div> */}
          </nav>
        </div>

        {/* Mobile menu */}

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <StaticImage
                    className=""
                    src="../images/avert-logo.svg"
                    alt="Avert logo"
                    placeholder="TRACED_SVG"
                    layout="fixed"
                    width={150}
                    height={38}
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close main menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {/* {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))} */}

                {/* Who we are */}
                <div
                  key="Who we are"
                  className="pt-3 text-lg block px-4 py-1 rounded-md font-medium text-gray-700"
                >
                  Who we are
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our story"
                      to="/who-we-are/our-story"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our story
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our people"
                      to="/who-we-are/our-people"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our people
                        </p>
                      </div>
                    </Link>
                  </div>
                  {/* <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our partners"
                      to="/who-we-are/our-partners"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our partners
                        </p>
                      </div>
                    </Link>
                  </div> */}
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Annual reports"
                      to="/who-we-are/our-annual-reports"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Key reports
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* Who we are */}

                {/* What we do */}
                <div
                  key="What we do"
                  className="pt-3 text-lg block px-4 py-1 rounded-md font-medium text-gray-700"
                >
                  Our work
                  {/* <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8"> */}
                  {/* <Link
                      key="Our strategy"
                      to="/what-we-do/our-strategy"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our strategy
                        </p>
                      </div>
                    </Link>
                  </div> */}
                  {/* <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our approach"
                      to="/what-we-do/our-approach"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our approach
                        </p>
                      </div>
                    </Link>
                  </div> */}
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our work"
                      to="/what-we-do/our-work"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          What we did
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our transition"
                      to="/what-we-do/our-transition"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our transition
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our impact"
                      to="/what-we-do/our-impact"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our impact
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Our legacy"
                      to="/what-we-do/our-legacy"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Our legacy
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* What we do */}

                {/* News & events */}
                <div
                  key="What we do"
                  className="pt-3 text-lg block px-4 py-1 rounded-md font-medium text-gray-700"
                >
                  HIV timeline
                  {/* <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="News and events"
                      to="/news-and-events"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          News and updates
                        </p>
                      </div>
                    </Link>
                  </div> */}
                  {/* <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="Avert newsletter"
                      to="/newsletter"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          Avert newsletter
                        </p>
                      </div>
                    </Link>
                  </div> */}
                  <div className="relative grid gap-2 bg-white px-2 py-2 sm:gap-8 sm:p-8">
                    <Link
                      key="HIV timeline"
                      // to="https://timeline.avert.org"
                      to="/news-and-events/hiv-timeline"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          HIV timeline
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* News & events */}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

export default TopNav;
